import React, { useCallback, useState } from "react";
import {
  Box,
  Center,
  HStack,
  Image,
  List,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors, other } from "src/theme";
import Confetti from "react-confetti";
import { motion } from "framer-motion";
import { useMe, useMyToast } from "src/hooks";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isEmpty, isNil } from "lodash/fp";
import { Button } from "src/components/Button";
import { useTheme } from "src/hooks/useTheme";
import { useActiveFamily } from "src/hooks/useActiveFamily";
import { useLazyQuery } from "@apollo/client";
import { api } from "src/api";
import { Query } from "src/api/generated/types";

export function Welcome() {
  const toast = useMyToast();
  const { familySlug } = useParams();

  const [runConfetti, setRunConfetti] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isElder = searchParams.get("isElder") === "true";
  const theme = useTheme();

  // get active user
  const [getActiveFamilyMember] = useLazyQuery<
    Pick<Query, "getActiveFamilyMember">
  >(api.families.getActiveFamilyMember);

  const _goToNext = async () => {
    const response = await getActiveFamilyMember();

    const hasPhone = !!response.data?.getActiveFamilyMember?.phone;
    const familyMemberId = response.data?.getActiveFamilyMember?.id;

    if (hasPhone) {
      navigate(`/app/${familySlug}/members/${familyMemberId}/schedule`);

      return;
    }

    navigate(`/app/${familySlug}/members/onboard`);
  };

  const _onContinue = async () => {
    if (isElder) {
      // if they are the elder, we can probably skip some steps
      return _goToNext();
    }

    // otherwise we need to make the elder
    navigate(`/app/${familySlug}/members/new`);
  };

  return (
    <div>
      <VStack
        padding="2rem 0"
        flexDir="column"
        display="flex"
        justifyContent="center"
      >
        <Confetti
          run={runConfetti}
          colors={[
            // colors.blue50,
            // colors.green50,
            // colors.purple50,
            // colors.red50,
            colors.yellow50,
            colors.orange50,
            // colors.pink50,
          ]}
          numberOfPieces={250}
          recycle={false}
        />

        <Box
          display="flex"
          flexDir="column"
          h="100%"
          maxWidth="30rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={colors.white}
          border={other.boxBorder}
        >
          <Box width="100%">
            <Text
              className="spectral-bold"
              style={{ fontSize: 24, fontWeight: "bold" }}
              marginBottom="1rem"
            >
              Welcome to Ruth 👋!
            </Text>

            <Text color={theme.text} fontSize="lg" marginBottom="1rem">
              {isElder
                ? "Ruth is here to keep you company. Every day, Ruth will call you to chat about anything."
                : "Ruth is here to help keep your loved ones company. Every day, Ruth calls your loved ones to chat."}
            </Text>

            <List style={{ padding: "2rem 0" }} marginBottom="0 !important">
              <ListItem
                iconName="fas fa-scroll"
                header="Memories"
                subtitle="Record loved ones stories."
              />

              <ListItem
                iconName="fas fa-calendar-alt"
                header="Daily check-in"
                subtitle="Check in with loved ones daily."
              />

              <ListItem
                iconName="fas fa-pills"
                header="Medications"
                subtitle="Get reminders about medications."
              />

              <ListItem
                iconName="fas fa-heart"
                header="Friend"
                subtitle="Chat about anything."
              />
            </List>

            <motion.div whileHover={{ scale: 1 }} whileTap={{ scale: 1 }}>
              <Button
                onClick={_onContinue}
                padding="1.5rem 0.5rem"
                disabled={runConfetti}
                bg={`linear-gradient(30deg, ${colors.primary} 0%, ${colors.green10} 50%, ${colors.primary} 100%) !important`}
                color={colors.white}
                style={{ width: "100%" }}
              >
                Continue
              </Button>
            </motion.div>
          </Box>
        </Box>
      </VStack>
    </div>
  );
}

const ListItem = ({
  header,
  subtitle,
  iconName,
  backgroundColor = colors.gray80,
}: {
  subtitle: string;
  header: string;
  iconName: string;
  backgroundColor?: string;
}) => {
  const theme = useTheme();

  return (
    <HStack marginBottom="1.5rem">
      <div
        style={{
          width: 45,
          height: 45,
          backgroundColor: colors.primary,
          borderRadius: 50,
          flexShrink: 0,
          display: "flex",
          fontSize: 18,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: colors.gray30,
          marginRight: "0.5rem",
        }}
      >
        <i className={iconName} style={{ color: colors.white }} />
      </div>
      <VStack
        alignItems="flex-start"
        style={{
          gap: 0,
        }}
      >
        <Text
          className="spectral-extrabold "
          style={{ fontSize: 18, fontWeight: "bold" }}
          color={theme.header}
          marginBottom="0 !important"
        >
          {header}
        </Text>
        <Text color={theme.text} marginTop="0 !important" fontSize="md">
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  );
};
