import {
  Button,
  Container,
  Text,
  UnorderedList,
  ListItem,
  Link,
  HStack,
  Switch,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { Modal } from "../Modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationSetScheduleArgs,
  Query,
} from "src/api/generated/types";
import { Input, Option, Select } from "../Form";
import { useMyToast } from "src/hooks";
import { BaseFamilyMemberFields } from "src/api/fragments";
import moment from "moment";
import { Touchable } from "../Touchable";
import { colors } from "src/theme";
import { useDownloadFile } from "src/hooks/common";

type Props = InjectedProps & {
  familyMember: BaseFamilyMemberFields;
};

const RUTH_CONTACT = "https://ruth-api.onrender.com/ruth-contact";

function _ShareRuthContactModal({
  handleHide,
  show: isVisible,
  familyMember,
}: Props) {
  const { download } = useDownloadFile();

  const _sendToFriend = () => {
    const message = `sms:${familyMember?.phone}?&body=Hey! You're going to be getting a call from Ruth tomorrow morning. Add her to your contacts here: ${RUTH_CONTACT}`;
    window.open(message);
  };

  const _addToMyContacts = () => {
    download(RUTH_CONTACT);
  };

  return (
    <Modal
      title={`Add Ruth to contacts`}
      isVisible={isVisible}
      handleHide={handleHide}
    >
      <RuthContactOption
        label="Share with someone else"
        iconName="fas fa-share"
        onClick={_sendToFriend}
        bg={colors.gray80}
      />

      <RuthContactOption
        label="Add to my contacts"
        iconName="fas fa-address-book"
        onClick={_addToMyContacts}
        bg={colors.gray80}
      />

      <br />
      <br />
    </Modal>
  );
}

const RuthContactOption = ({
  label,
  iconName,
  onClick,
  bg,
}: {
  label: string;
  iconName: string;
  onClick: () => void;
  bg: string;
}) => {
  const theme = useTheme();

  return (
    <HStack
      style={{
        borderRadius: 15,
        padding: 15,
        cursor: "pointer",
      }}
      _hover={{
        backgroundColor: theme.secondaryBackground,
      }}
      onClick={onClick}
    >
      <Box
        style={{
          backgroundColor: bg,
          borderRadius: 100,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: 40,
          height: 40,
        }}
      >
        <i className={iconName} />
      </Box>

      <Text flex={1} fontSize="large">
        {label}
      </Text>

      <i className="fas fa-chevron-right" />
    </HStack>
  );
};

export const ShareRuthContactModal = connectModal({
  name: "ShareRuthContactModal",
})(_ShareRuthContactModal);
