import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Center, Text, VStack, HStack } from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationAddFamilyMemberArgs,
  MutationUpdateActiveFamilyMemberArgs,
  Query,
  QueryGetFamilyMemberArgs,
} from "src/api/generated/types";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { FamilyMemberSchedule } from "../FamilyMember/Schedule";
import { Button } from "src/components/Button";
import { useActiveFamily } from "src/hooks/useActiveFamily";

export function EditSchedule() {
  const navigate = useNavigate();

  const { familySlug, familyMemberId } = useParams();
  const { activeFamily } = useMe();

  const variables = useMemo(
    (): QueryGetFamilyMemberArgs => ({
      familyMemberId: familyMemberId || "",
    }),
    [familyMemberId]
  );

  const { data: familyMemberData } = useQuery<Pick<Query, "getFamilyMember">>(
    api.families.getMember,
    {
      variables,
      skip: !variables.familyMemberId,
    }
  );

  const familyMember = familyMemberData?.getFamilyMember ?? null;

  const theme = useTheme();

  const _onSubmit = () => {
    navigate(`/app/${familySlug}/members/${familyMemberId}/onboard`);
  };

  // API hooks
  return (
    <div style={{}}>
      <VStack padding="2rem 0" flexDir="column" display="flex">
        <Box
          display="flex"
          flexDir="column"
          maxWidth="40rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <Text
            className="spectral-bold"
            style={{
              color: theme.header,
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Call schedule for {familyMember?.name}
          </Text>

          <Text fontSize="lg" style={{ marginTop: 10 }}>
            You can call Ruth whenever you want, but by default she'll call you
            according to the schedule below. You can edit this at anytime.
          </Text>

          <br />

          <FamilyMemberSchedule familyMember={familyMember ?? null} />

          <br />

          <Button variant="primary" onClick={_onSubmit}>
            Continue
          </Button>
        </Box>
      </VStack>
    </div>
  );
}
