import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
  Flex,
} from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { colors, other } from "src/theme";
import "react-h5-audio-player/lib/styles.css";
import { DailyPromptModal } from "src/components/modals";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { Touchable } from "src/components/Touchable";
import { FamilyMemberDescriptionModal } from "src/components/modals/FamilyMemberDescriptionModal";
import moment from "moment-timezone";
import { FamilyMemberModal } from "src/components/modals/FamilyMemberModal";
import { DailyPromptRow } from "./DailyPromptRow";
import { FamilyMemberContext } from "./context";
import { ScheduleModal } from "src/components/modals/ScheduleModal";
import { getTZAbb } from "src/utils/helpers";
import { FamilyMemberSchedule } from "./Schedule";
import { useTheme } from "src/hooks/useTheme";
import {
  Mutation,
  MutationStartCallArgs,
  Query,
  QueryGetFamilyMemberMetricsArgs,
} from "src/api/generated/types";
import { api } from "src/api";
import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

function Details() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { familyMember } = useContext(FamilyMemberContext);
  const [call] = useMutation<Pick<Mutation, "startCall">>(api.phones.call);
  const formattedPhone = familyMember?.formattedPhone;
  const { familyMemberId } = useParams<{ familyMemberId: string }>();
  const isLarge = useIsLargeScreen();

  const _shareContact = () => {
    dispatch(
      show("ShareRuthContactModal", {
        familyMember,
      })
    );
  };

  const startCall = async () => {
    try {
      const confirmed = window.confirm(
        `This will call the phone number of ${familyMember?.name} ${
          formattedPhone || "-"
        }. Are you sure?`
      );

      if (!confirmed) {
        return;
      }

      const variables: MutationStartCallArgs = {
        familyMemberId: familyMemberId || "",
      };

      await call({
        variables,
      });
    } catch (err) {
      console.log(err);
      // return
    }
  };

  return (
    <Box display="flex" flexDir="column" margin="auto" w="100%">
      <HStack>
        <div
          style={{
            flex: 1,
          }}
        >
          <HStack style={{ alignItems: "center" }}>
            <Text
              style={{
                fontSize: 22,
                color: theme.header,
                fontWeight: "bold",
                fontFamily: "Spectral",
              }}
            >
              {familyMember?.name}{" "}
            </Text>
            <Touchable
              style={{
                marginLeft: 5,
                marginTop: 0,
                color: theme.text,
                display: "inline-block",
              }}
              onClick={() => {
                dispatch(
                  show("FamilyMemberModal", {
                    familyMember,
                  })
                );
              }}
            >
              Edit{" "}
              <i
                className="fas fa-pen"
                style={{
                  fontSize: 14,
                  color: theme.text,
                }}
              />
            </Touchable>
          </HStack>

          <Text
            style={{
              padding: "0 0",
              fontSize: 18,
              color: theme.text,
            }}
          >
            {familyMember?.formattedPhone || ""}
          </Text>
        </div>

        {isLarge && (
          <div>
            {/*  render the phone with an edit button */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {familyMember?.nextCallAt ? (
                <HStack>
                  <Text
                    style={{
                      fontSize: 16,
                      color: theme.text,
                    }}
                  >
                    Next call @{" "}
                    <b>
                      {moment
                        .tz(familyMember?.nextCallAt, familyMember?.timezone)
                        .format("MMM Do, h:mma")}{" "}
                      {getTZAbb(familyMember?.timezone || "UTC")}
                    </b>
                  </Text>
                </HStack>
              ) : null}

              <HStack
                style={{
                  marginTop: 5,
                }}
              >
                <Touchable
                  onClick={_shareContact}
                  style={{
                    display: "inline-block",
                    // textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Add Ruth to contacts <i className="fas fa-user-plus" />
                </Touchable>

                <Touchable
                  onClick={startCall}
                  style={{
                    display: "inline-block",
                    color: colors.primary,
                    background: colors.primaryLight,
                    fontWeight: "bold",
                  }}
                >
                  Call now <i className="fas fa-phone-volume" />
                </Touchable>
              </HStack>
            </div>
          </div>
        )}
      </HStack>

      {!isLarge && (
        <div style={{ marginTop: 10 }}>
          {/*  render the phone with an edit button */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {familyMember?.nextCallAt ? (
              <HStack>
                <Text
                  style={{
                    fontSize: 16,
                    color: theme.text,
                  }}
                >
                  Next call @{" "}
                  <b>
                    {moment
                      .tz(familyMember?.nextCallAt, familyMember?.timezone)
                      .format("MMM Do, h:mma")}{" "}
                    {getTZAbb(familyMember?.timezone || "UTC")}
                  </b>
                </Text>
              </HStack>
            ) : null}

            <HStack
              style={{
                marginTop: 15,
              }}
            >
              <Touchable
                onClick={_shareContact}
                style={{
                  display: "inline-block",
                  // textDecoration: "underline",
                  fontWeight: "bold",
                  background: theme.secondaryBackground,
                }}
              >
                Add Ruth to contacts <i className="fas fa-user-plus" />
              </Touchable>

              <Touchable
                onClick={startCall}
                style={{
                  display: "inline-block",
                  color: colors.primary,
                  background: colors.primaryLight,
                  fontWeight: "bold",
                }}
              >
                Call now <i className="fas fa-phone-volume" />
              </Touchable>
            </HStack>
          </div>
        </div>
      )}

      <div
        style={{
          marginTop: 25,
          padding: 20,
          border: `1px solid ${theme.border}`,
          borderRadius: 15,
          position: "relative",
        }}
      >
        <Touchable
          style={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={() =>
            dispatch(
              show("FamilyMemberDescriptionModal", {
                familyMember,
              })
            )
          }
        >
          <i
            className="fas fa-pen"
            style={{
              color: theme.text,
            }}
          />
        </Touchable>

        {familyMember?.description ? (
          <div style={{ marginBottom: 15 }}>
            <Text
              style={{
                flex: 1,
                fontSize: 12,
                color: theme.text,
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Biography
            </Text>

            <Text
              style={{
                marginTop: 5,
                flex: 1,
                fontSize: 16,
                color: theme.header,
              }}
            >
              {familyMember?.description || ""}
            </Text>
          </div>
        ) : null}

        <Text
          style={{
            flex: 1,
            fontSize: 12,
            color: theme.text,
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Interests
        </Text>

        <Text
          style={{
            marginTop: 5,
            flex: 1,
            fontSize: 16,
            color: theme.header,
          }}
        >
          {familyMember?.interestDescription || ""}
        </Text>
      </div>

      <br />

      <FamilyMemberMetrics />

      <br />

      <FamilyMemberSchedule familyMember={familyMember ?? null} />
    </Box>
  );
}

const FamilyMemberMetrics = () => {
  const { familyMemberId } = useParams<{ familyMemberId: string }>();
  const theme = useTheme();

  const variables = useMemo(
    (): QueryGetFamilyMemberMetricsArgs => ({
      familyMemberId: familyMemberId || "",
    }),
    [familyMemberId]
  );

  const { data } = useQuery<Pick<Query, "getFamilyMemberMetrics">>(
    api.families.metrics,
    {
      variables,
      skip: !variables.familyMemberId,
    }
  );

  const metrics = data?.getFamilyMemberMetrics;
  const missedCalls = metrics?.missedCallsTotal || 0;
  const completedCalls = metrics?.completedCallsTotal || 0;
  const totalCalls = missedCalls + completedCalls;
  const completionPercentage =
    totalCalls > 0 ? (completedCalls / totalCalls) * 100 : 0;
  const isLarge = useIsLargeScreen();

  return (
    <HStack style={{ marginTop: 25 }}>
      <Box
        style={{
          background: colors.positiveLight,
          border: `1px solid ${theme.border}`,
          borderRadius: 15,
          padding: 15,
          flex: 1,
          cursor: "pointer",
        }}
      >
        <HStack
          style={{
            color: colors.gray05,
          }}
        >
          <Text
            style={{
              flex: 1,
              fontSize: 28,
              fontWeight: "900",
            }}
          >
            {completedCalls}
          </Text>
          {/* missed phone */}
          <i className="fas fa-phone" />
        </HStack>

        <Text
          style={{
            flex: 1,
            fontSize: 14,
            fontWeight: "normal",
          }}
        >
          Answered
        </Text>
      </Box>

      <Box
        style={{
          background: colors.negativeLight,
          border: `1px solid ${theme.border}`,
          borderRadius: 15,
          padding: 15,
          flex: 1,
          cursor: "pointer",
        }}
      >
        <HStack
          style={{
            color: colors.gray05,
          }}
        >
          <Text
            style={{
              flex: 1,
              fontSize: 28,
              fontWeight: "900",
            }}
          >
            {missedCalls}
          </Text>
          {/* missed phone */}
          <i className="fas fa-phone-slash" />
        </HStack>

        <Text
          style={{
            flex: 1,
            fontSize: 14,
            fontWeight: "normal",
          }}
        >
          Missed
        </Text>
      </Box>

      <Box
        style={{
          border: `1px solid ${theme.border}`,
          background: colors.gray100,
          borderRadius: 15,
          padding: 15,
          flex: 1,
          cursor: "pointer",
        }}
      >
        <HStack
          style={{
            position: "relative",
            color: colors.gray05,
          }}
        >
          <Text
            style={{
              flex: 1,
              fontSize: 28,
              fontWeight: "900",
            }}
          >
            {completionPercentage.toFixed(2)}%
          </Text>

          {isLarge && (
            <AnswerRateCircularProgress percentage={completionPercentage} />
          )}
        </HStack>

        <Text
          style={{
            flex: 1,
            fontSize: 14,
            fontWeight: "normal",
          }}
        >
          Answer Rate
        </Text>
      </Box>
    </HStack>
  );
};

const AnswerRateCircularProgress = ({ percentage }: { percentage: number }) => {
  const theme = useTheme();
  // more than 75, green. between 25 and 75 is yellow50, otherwise red
  const color =
    percentage > 75
      ? colors.positive
      : percentage > 25
      ? colors.yellow50
      : colors.negative;

  return (
    <CircularProgress
      value={percentage}
      size="35px"
      style={{
        position: "absolute",
        top: 0,
        right: 0,
      }}
      thickness="15px"
      capIsRound
      color={color}
      trackColor={theme.secondaryBackground}
    >
      {/* <CircularProgressLabel
        color={theme.text}
        fontSize="x-small"
        fontWeight="bold"
      >
        {percentage}%
      </CircularProgressLabel> */}
    </CircularProgress>
  );
};

export default Details;
