import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Center, Text, VStack, HStack } from "@chakra-ui/react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import {
  Mutation,
  MutationAddFamilyMemberArgs,
  Query,
  QueryGetFamilyMemberArgs,
} from "src/api/generated/types";
import { useMe, useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { Input, Textarea } from "src/components/Form";
import { Button } from "src/components/Button";
import { useActiveFamily } from "src/hooks/useActiveFamily";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { ShareRuthContactModal } from "src/components/modals/ShareRuthContactModal";

export function OnboardFamilyMember() {
  const navigate = useNavigate();

  const { familyMemberId, familySlug } = useParams();

  const [hasClickedAdd, setHasClickedAdd] = React.useState(false);
  const [name, setName] = React.useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = React.useState<string | null>(null);
  const [biography, setBiography] = React.useState<string | null>(null);

  const theme = useTheme();

  const variables = useMemo(
    (): QueryGetFamilyMemberArgs => ({
      familyMemberId: familyMemberId || "",
    }),
    [familyMemberId]
  );

  const { activeFamily, activeFamilyMember } = useActiveFamily();

  const { data: familyMemberData } = useQuery<Pick<Query, "getFamilyMember">>(
    api.families.getMember,
    {
      variables,
    }
  );

  const dispatch = useDispatch();

  const _onNext = () => {
    setHasClickedAdd(true);

    dispatch(
      show("ShareRuthContactModal", {
        familyMember: familyMemberData?.getFamilyMember,
      })
    );
  };

  const familyMember = familyMemberData?.getFamilyMember;
  const isYou = familyMember?.id === activeFamilyMember?.id;

  return (
    <div style={{}}>
      <ShareRuthContactModal />
      <VStack padding="2rem 0" flexDir="column" display="flex">
        <Box
          display="flex"
          flexDir="column"
          maxWidth="40rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <Text
            className="spectral-bold"
            style={{
              color: theme.header,
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Add Ruth to your contacts
          </Text>

          <Text marginTop={15} fontSize="lg" color={theme.text}>
            Add Ruth to your contacts to get the best experience, and to make
            sure that {isYou ? "you" : "your loved ones"} don't miss any calls.
            {/* {isYou
              ? "Every day, we'll call you to learn more about your life and chat with you about anything you want."
              : "Every day, we'll call your relative to learn more about their life, chat with them, and keep them company."} */}
          </Text>

          <br />

          <Text color={theme.text} fontSize="lg">
            We'll call from this phone number:{" "}
            <b>{familyMember?.formattedFromPhoneNumber}</b>
          </Text>

          <br />
          <br />

          <Button
            variant={hasClickedAdd ? "secondary" : "primary"}
            onClick={_onNext}
          >
            Add Ruth to contacts
          </Button>

          {/* or skip */}
          {!hasClickedAdd ? (
            <Text
              color={theme.text}
              style={{ marginTop: 15 }}
              fontSize="md"
              textAlign="center"
            >
              <Link to={`/app/${familySlug}/members/${familyMemberId}`}>
                Or skip for now
              </Link>
            </Text>
          ) : (
            <Button
              style={{ marginTop: 15 }}
              variant="primary"
              onClick={() =>
                navigate(`/app/${familySlug}/members/${familyMemberId}`)
              }
            >
              Continue
            </Button>
          )}
        </Box>
      </VStack>
    </div>
  );
}
